<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <md-button
          class="md-primary float-right md-round md-sm"
          style="margin-top: -20px"
          :disabled="!tableHasContent"
          @click="onDownload"
        >
          <md-icon>get_app</md-icon>
          Download as XLS
        </md-button>
      </md-card-header>

      <md-card-content>
        <div class="md-layout mb-5">
          <div class="md-layout-item md-size-50">
            <ProgrammeStatusesSelect
              v-model="filters.status"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <CountriesAutocomplete
              v-model="filters.country_id"
              label="Nationality"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-date
              v-model="filters.from"
              name="dateFrom"
              label="Attendance Date From"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-date
              v-model="filters.to"
              name="dateTo"
              label="Attendance Date To"
            />
          </div>

          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getAttendance"
              @onGoToProgrammes="onGoToProgrammes"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { FormDate } from '@/components/Inputs';
import { CountriesAutocomplete, ProgrammeStatusesSelect } from '@/components/Inputs/selects';
import moment from 'moment';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    VueTable,
    FormDate,
    CountriesAutocomplete,
    ProgrammeStatusesSelect,
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: 'student.student_number',
          mask: 'student no.',
          sortable: true,
        },
        {
          title: 'programme_id',
          mask: 'Prog. ID',
          sortable: true,
        },
        {
          title: 'student.first_name',
          mask: 'given name',
          sortable: true,
        },
        {
          title: 'student.last_name',
          mask: 'surname',
          sortable: true,
        },
        {
          title: 'student.email',
          mask: 'email',
          sortable: true,
        },
        {
          title: 'student.country.nationality',
          mask: 'Nationality',
          sortable: true,
        },
        {
          title: 'start_date',
          mask: 'start date',
          sortable: true,
          dateFormat: true,
        },
        {
          title: 'finish_date',
          mask: 'finish date',
          sortable: true,
          dateFormat: true,
        },
        {
          title: 'status',
          mask: 'status',
          sortable: true,
        },
        {
          title: 'performed_days',
          mask: 'performed days',
          sortable: false,
        },
        {
          title: 'attended_days',
          mask: 'attended days',
          sortable: false,
        },
        {
          title: 'absent_days',
          mask: 'absent days',
          sortable: false,
        },
        {
          title: 'attendance_rate',
          mask: 'attendance rate',
          sortable: false,
          after: '%',
        },
      ],
      values: {},
      actions: [
        {
          buttonClass: 'md-primary',
          tooltip: 'Student Programmes',
          callback: 'onGoToProgrammes',
          icon: 'double_arrow',
        },
      ],
    },
    filters: {
      from: moment().subtract(1, 'month').startOf('day'),
      to: moment().startOf('day'),
      course_level_id: null,
      country_id: null,
    },
    params: {},
    loading: false,
  }),
  computed: {
    tableHasContent() {
      return this.vTable.values.data?.length > 0;
    },
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getAttendance(params) {
      this.params = params;
      this.request('attendance_by_date/report', 'get', { ...this.filters, ...this.params }, ({ data }) => {
        this.vTable.values = data;
      });
    },
    onFilter() {
      this.$refs.vtable.init();
    },
    onDownload() {
      this.loading = true;
      this.request('attendance_by_date/report', 'download', { ...this.filters, ...this.params, download: true }, ({ data }) => {
        const filename = `attendance_report_by_date_${moment().format('YYYY_MM_DD')}.xlsx`;
        downloadFile(data, filename);
      }, () => {
        this.loading = false;
      });
    },
    onGoToProgrammes(item, isMiddleClick) {
      this.openLink({ name: 'StudentProgrammes', params: { id: item.student_id } }, isMiddleClick);
    },
  },
};
</script>
